import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import UiSelectFieldMultiple from '../_app/filter-fields/UiSelectFieldMultiple';
import UiSliderField from '../_app/filter-fields/UiSliderField';
import {declOfNum} from '../../utils';
import CalcApartmentRow from './CalcApartmentFlatRow';
import {setCalcAll, setCalcPopup} from '../../redux/modules/calc'
import axios from "axios";

const CalcApartment = ({buildingId, defaultPriceMax, defaultPriceMin, item, api, menu_ipo,menu_ipo_fix, main}) => {
  const dispatch = useDispatch();
  const frm = new Intl.NumberFormat('ru-RU');

  const mobile = useSelector(state => state.mobile);
  const data = useSelector(state => state.calc.response);
  const [programs, setPrograms] = useState(null);
  const [price, setPrice] = useState(defaultPriceMax ? parseInt(defaultPriceMax) : parseInt(data.defaultCost));
  const [priceFix, setPriceFix] = useState(defaultPriceMax ? parseInt(defaultPriceMax) : parseInt(data.defaultCost));
  const defaultPercent = useSelector(state => state.calc.defaultPercent);
  const [dataLoaded, setDataLoaded] = useState(false);

/*  const mortgageBut = {
    name: 'Ипотека по&nbsp;QR-коду',
    url: 'https://estatet.ru',
    icon: '/static/img/qr.svg'
  }*/

  const initPercent = () => {
    if(!buildingId){
      if(defaultPercent){
        const tmpPrice = parseInt(data.defaultCost);

        if(defaultPercent > tmpPrice){
          return tmpPrice;
        }

        if(defaultPercent < data.fee[0]){
          return parseInt(data.fee[0]);
        }

        return defaultPercent;
      }
    }
    if(defaultPriceMax){
      return parseInt(defaultPriceMax*0.201);
    }else{
      return parseFloat(data.defaultFee);
    }
  }
  const [percent, setPercent] = useState(initPercent());
  const [percentFix, setPercentFix] = useState(initPercent());
  const [programsName, setProgramsName] = useState([]);
  const [programsType, setProgramsType] = useState([]);
  const [term, setTerm] = useState(parseFloat(data.defaultTerm));
  const [more, setMore] = useState(3);
  const [buildings, setBuildings] = useState(buildingId ? [buildingId] : []);


  const getPrograms = () => {
    let url = '/ajax/json.php?page=mortgage-calc-digital';
    if(!main){
      if(buildingId){
        url = url + '&object_id=' + buildingId;
      }
      if(price){
        url = url + '&cost=' + price;
      }
      if(percent){
        url = url + '&initial_payment=' + percent;
      }
    }else{
      if(buildings.length){
        setDataLoaded(true);
        //url = url + '&initial_payment=' + percent;
        if(buildings.length>1){
          buildings.map(item => {
            url = url + '&object_id[]=' + item;
          })
        }else{
          url = url + '&object_id=' + buildings[0];
        }

        if(priceFix){
          url = url + '&cost=' + priceFix;
        }
        if(percentFix){
          url = url + '&initial_payment=' + percentFix;
        }
      }
    }


    document.querySelector('body').classList.add('wait');

    axios.post(api + url)
      .then(function (response) {
        document.querySelector('body').classList.remove('wait');
        if (response?.data) {

          setPrograms(response?.data);

        }
      })
      .catch(function (error) {
        document.querySelector('body').classList.remove('wait');
      });
  }

  useEffect(() => {
    if(!programs){
      getPrograms();
    }
  },[]);

/*  useEffect(() => {
    getPrograms();
  },[programsType]);*/

  useEffect(() => {
    if(buildings?.length || dataLoaded){
      getPrograms();
    }
  },[buildings]);

  if(!programs && !dataLoaded) return false;

  const types = [
    {
      "name": "Семейная ипотека",
      "value": "FAMILY"
    },
/*    {
      "name": "Дальневосточная",
      "value": "FAR_EAST"
    },*/
    {
      "name": "Гос.поддержка",
      "value": "GOVERNMENT_SUPPORT"
    },
    {
      "name": "Ипотека для IT специалистов",
      "value": "IT"
    },
    {
      "name": "Военная ипотека",
      "value": "MILITARY"
    },
    {
      "name": "Стандартная ипотека",
      "value": "STANDARD"
    },
  ];

  const createSelect = () => {
    let array = [];
    let array_names = [];
    if(!programs.error){
      programs.map(item => {
        let n = item.name?.split(' ~ ')[0];
        if(!array_names.includes(n)){
          array.push({name: n, value: n})
          array_names.push(n);
        }
      });
    }

    array.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    return array;
  }

  const changePrice = value => {
    setPrice(value)
    if(percent > value){
      setPercent(value)
    }
  }
  const changePercent = value => {
    if(value > price){
      setPercent(price)
    }
    else{
      setPercent(value)
    }
  }
  const changeMore = array => {
    if(array[0] == more[0] && array[1] == more[1]){
      setMore([]);
    }
    else{
      setMore(array);
    }
  }

  const calc = rate => {
    const monthRate = parseFloat(rate) / 12 / 100;
    const allRate = Math.pow((1 + monthRate), (term * 12))
    const monthPayment = ( price - (percent) ) * monthRate * allRate / (allRate - 1)
    const result = frm.format(parseInt(monthPayment));
    return monthPayment <= 0 ? 0 : result
  }

  const calcAll = (rate) => {
    const monthRate = rate / 12 / 100;
    const allRate = Math.pow((1 + monthRate), (term * 12))
    const monthPayment = ( price - (percent) ) * monthRate * allRate / (allRate - 1)
    const result = frm.format(parseInt(monthPayment))
    dispatch(setCalcAll(result))
    return `от ${monthPayment <= 0 ? 0 : result} ₽`;
  }
  let dataSlice = [];
  if(!programs?.error){
    dataSlice = programs?.filter(function( prog, i ) {
      const perc = (percent/price)*100;
      return prog.minInitialPayment<=perc && prog.maxInitialPayment>=perc;
    });
  }

  if(programsName.length>0){
    dataSlice = dataSlice.filter(function( prog, i ) {
      return programsName.includes(prog.name.split(' ~ ')[0])
    });
  }
  if(programsType.length>0){
    dataSlice = dataSlice.filter(function( prog, i ) {
      return programsType.includes(prog.mortgageType)
    });
  }

  dataSlice = [...dataSlice].splice(0, more);

  if(menu_ipo?.current){
    menu_ipo?.current.classList.remove('none');
  }
  if(menu_ipo_fix?.current){
    menu_ipo_fix?.current.classList.remove('none');
  }

  if(!programs.length && !dataLoaded){
    if(menu_ipo?.current){
      menu_ipo?.current.classList.add('none');
    }
    if(menu_ipo_fix?.current){
      menu_ipo_fix?.current.classList.add('none');
    }
    return false;
  }



  return(
    <>
      <div className="frame">
        <div className="title">Ипотека</div>
        <div className="apartment-calc">
          <div>
            <div className="apartment-calc__title">Выберите программы и&nbsp;внесите информацию о&nbsp;квартире для&nbsp;расчета</div>


{/*            <div className="apartment-calc__field">
              <UiSelectFieldMultiple
                options={createSelect()}
                values={programsName}
                onChange={e => setProgramsName(e.target.value)}
                title="Программы"
              />
            </div>*/}

            <div className="apartment-calc__field">
              <UiSelectFieldMultiple
                options={types}
                values={programsType}
                onChange={e => setProgramsType(e.target.value)}
                title="Программы"
              />
            </div>

            <div className="apartment-calc__field">
              <UiSliderField
                //defaultValue={defaultPriceMax || parseFloat(data.cost[1]) || price}
                defaultValue={price}
                min={defaultPriceMin || parseFloat(data.cost[0])}
                max={defaultPriceMax || parseFloat(data.cost[1])}
                onChange={changePrice}
                title="Стоимость квартиры"
                labels={[`Мин: ${frm.format(defaultPriceMin || data.cost[0])} ₽`, `Макс: ${frm.format(defaultPriceMax || data.cost[1])} ₽`]}
              />
            </div>

            <div className="apartment-calc__field">
              <UiSliderField
                defaultValue={percent}
                min={parseFloat(data.fee[0])}
                max={parseFloat(data.fee[1])}
                onChange={changePercent}
                title="Первоначальный взнос"
                labels={[`Мин: ${frm.format(data.fee[0])} ₽`, `Макс: ${frm.format(data.fee[1])} ₽`]}
              />
            </div>

            <div className="apartment-calc__field">
              <UiSliderField
                defaultValue={term}
                min={parseFloat(data.term[0])}
                max={parseFloat(data.term[1])}
                onChange={setTerm}
                title="Срок"
                labels={[`Мин: ${data.term[0]} ${declOfNum(data.term[0], ['год', 'года', 'лет'])}`, `Макс: ${data.term[1]} ${declOfNum(data.term[1], ['год', 'года', 'лет'])}`]}
              />
            </div>

            {!buildingId && (<div className="apartment-calc__field">
              <UiSelectFieldMultiple
                options={data.buildings}
                values={buildings}
                onChange={e => setBuildings(e.target.value)}
                title="Жилой комплекс"
              />
            </div>)}

          </div>
          <div>
            <div className="apartment-calc__result">
              <div className="apartment-calc__result-title">Ежемесячный платеж: <span>{dataSlice.length > 0 ? calcAll(dataSlice[0].rate) : ''}*</span></div>
              <div className="apartment-calc__result-subtitle">* Расчёт является предварительным, необходима консультация ипотечного брокера</div>

              {!mobile ?
                <div className="apartment-calc__table">
                  <table>
                    <thead>
                    <tr>
                      <td/>
                      <td className={'mort_table_name'}>Программа</td>
                      <td>Процент</td>
                      <td>ПВ</td>
                      <td/>
                    </tr>
                    </thead>
                    <tbody>
                    {dataSlice.map((item, index) =>
                      <CalcApartmentRow
                        key={`item${index}`}
                        item={item}
                        value={'от ' + calc(item.rate) + ' ₽'}
                      />
                    )}
                    </tbody>
                  </table>
                </div>
                :
                <div className="apartment-calc__table_mob">
                  {dataSlice.map((item, index) =>
                    <div  key={`item_mob${index}`} className={'ap_calc_mob_item'}>
                      <div className={'ap_calc_mob_item__top'}>
                        <div>{item.bankLogo && <img src={item.bankLogo} title={item.bankName}/>}</div>
                        <div>{item.name?.split(' ~ ')[0]}</div>
                      </div>
                      <div className={'ap_calc_mob_item__bottom'}>
                        <div>
                          <div className={'ap_calc_mob_item__title'}>Процент</div>
                          <div className={'ap_calc_mob_item__val'}>{item.rate}</div>
                        </div>
                        <div>
                          <div className={'ap_calc_mob_item__title'}>ПВ, %</div>
                          <div className={'ap_calc_mob_item__val'}>{item.minInitialPayment} - {item.maxInitialPayment}</div>
                        </div>
                        <div>
                          <div className={'ap_calc_mob_item__title'}>Платеж, ₽</div>
                          <div className={'ap_calc_mob_item__val -red'}>от {calc(item.rate)}</div>
                        </div>
                      </div>

                    </div>
                  )}
                </div>
              }



              <div className={'mortgage_buts'}>
                <div>
                  {more < programs.length && <div className="btn btn--border no-border-link apartment-calc__result-more-btn" onClick={() => setMore(more + 3)}><span>Показать ещё</span></div>}
                  <div className="btn btn--border no-border-link appartment-by-id__header-button--red" onClick={() => dispatch(setCalcPopup(true))}><span>{mobile ? 'Отправить заявку на ипотеку' : 'Отправить заявку на ипотеку'}</span></div>
                </div>

                {data?.mortgageBut &&
                  <a href={data?.mortgageBut?.url} target={'_blank'} className="btn btn--border no-border-link mortgage_but">{data?.mortgageBut?.icon ? <img src={data?.mortgageBut?.icon} alt={'mortgageBut'}/> : ''}<span dangerouslySetInnerHTML={{__html: data?.mortgageBut?.name}}/></a>
                }
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default CalcApartment;
