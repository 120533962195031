import {useState} from "react";

const ItemTable = ({data, mobile}) => {

  const [more, setVisible] = useState(false);

  return (
    <>
      <tr className="table__tr-top" onClick={() => setVisible(!more)}>
        <td><img src={data.img} title={data.name} /></td>
        <td>от {data.rate}%</td>
        <td>от {data.contribution}%</td>
        <td>до {data.term} лет</td>
        {/*!mobile && <td><span className="table__moreLnk">{!more ? 'Подробнее' : 'Свернуть'}</span> </td>*/}
      </tr>
      {/*<tr className="table__tr-bottom">
        <td colSpan={!mobile ? 5 : 4}>
            {more && (<div className="table__hidden fade-in">
              <span>Гражданство:</span> {data.nationality}<br/>
              <span>Стаж на последнем месте работы, мес:</span> {data.experience}
            </div>)}
        </td>
      </tr>*/}
    </>
  )
}

export default ItemTable
