import { useSelector } from 'react-redux';
import { useState } from 'react';
import { declOfNum } from '../../utils';

const CalcApartmentRow = ({item, value, value_rate, more, setMore, min_percent}) => {

  const mobile = useSelector(state => state.mobile);
  const frm = new Intl.NumberFormat('ru-RU');

  return(
    <>
      <tr>
        <td className={'mort_table_logo'}>{item.bankLogo && <img src={item.bankLogo} title={item.bankName}/>}</td>
        <td className={'mort_table_name'}><span>{item.name?.split(' ~ ')[0]}</span></td>
        <td><span>{item.rate}</span></td>
        <td className={'mort_table_fee'}><span>от&nbsp;{item.minInitialPayment}%&nbsp; до&nbsp;{item.maxInitialPayment}%</span></td>
        <td className="apartment-calc__result-value -right">{value}</td>

      </tr>
    </>
  )
}

export default CalcApartmentRow;
