import { useState } from 'react';
import ItemTable from "./itemTable";
import { useSelector } from 'react-redux';

const Programms = ({list, title}) => {

  const mobile = useSelector(state => state.mobile);
  const [size, setSize] = useState(3);

  const createBanks = data => {
    const tmp = [];
    let count = 0;
    for(let i in data){
      if(count < size){
        tmp.push(<ItemTable key={`item${i}${count}`} data={data[i]} mobile={mobile} />);
        count++;
      }
    }

    return tmp;
  }

  return (
    <div className="frame animate">
      {title && <div className="title">{title}</div>}
      <table className="table">
        <thead>
          <tr>
            <td>Банк</td>
            <td>Процент</td>
            <td>ПВ</td>
            <td>Срок</td>
          </tr>
        </thead>
        <tbody>
          {createBanks(list)}
        </tbody>
      </table>

      {size < list.length && <div className="btn btn--border no-border-link mortgage-programms-btn" onClick={() => setSize(size + 3)}><span>Показать еще</span></div>}
    </div>
  )
}

export default Programms;